<template>
  <div class="smallmenu">
    <router-link to="" :key="m.id" v-for="(m, index) in list">
      <span class="link-btn" v-if="index<9" :key="index" :class="{ 'is-active': navIndex == index }" @click="setNav(m, index)">{{ m.name }}</span>
    </router-link>
    <router-link to="" v-if="list.length>8">
      <div class="more-menu">
        <span class="link-btn" @click.stop="showNav=showNav?0:1">更多<i class="el-icon-arrow-down"></i></span>
        <ul class="nav-ul" v-show="showNav">
          <li class="list" :key="index" v-for="(m,index) in list.slice(9)" @click.stop="setNav(m, index+9)">{{m.name}}</li>
        </ul>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "dyNav",
  props: ["list"],
  data() {
    return {
      showNav: 0,
      navIndex: 0,
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    setNav(m, index) {
      this.$emit("setNav", m);
      if (m) {
        this.navIndex = index;
      }
      this.showNav = 0;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
// 二级导航
.smallmenu {
  text-align: center;

  .link-btn {
    padding: 20px;
    display: inline-block;
    font-size: 16px;
    &.is-active {
      color: #fd5621;
    }
  }
}
.more-menu {
  position: relative;
  display: inline-block;
  .nav-ul {
    position: absolute;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 5px #aaa;
    padding: 7px;
    min-width: 100px;
    line-height: 40px;
    z-index: 11;
    .list {
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/* 移动端 */
@media (max-width: 600px) {
  .smallmenu .link-btn {
    padding: 10px 5px;
  }
}
</style>
