<template>
    <div class="wrap">
        <dyNav :list="navList" @setNav="setNav"></dyNav>
        <div class="block" v-if="sList.length">
            <el-carousel height="150px">
                <el-carousel-item v-for="m in sList" :key="m.id">
                    <a :href="m.url ? m.url : 'javascript:;'">
                        <img class="s-img" :src="rImg(m.img)" :onerror="errImg" alt="">
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="content-wrap">
            <dyList :list="list"></dyList>
            <div class="page-box" v-show="total">
                <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>
    </div>

</template>

<script>
    import dyNav from "@components/common/dy-nav";
    import dyList from "@components/common/dy-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            dyList,
            dyNav,
        },
        data() {
            return {
                list: [],
                navId: 0,
                sList: [],
                total: 0,
                navList: [],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.setMeta("热浪-推荐", 1);
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getNav();
            this.getData(1);
            this.getSwiper();
        },
        methods: {
            // 获取导航栏
            getNav() {
                let cate = this.$y_getKey("cate");
                if (cate) {
                    let arr = [{
                        id: 0,
                        name: "推荐"
                    // }, {
                    //     id: -2,
                    //     name: "娱乐"
                    // }, {
                    //     id: -3,
                    //     name: "交友"
                    }];
                    this.navList = arr.concat(cate);
                }
            },
            // 切换栏目
            setNav(m) {
                this.navId = m.id;
                this.getData(1);
            },
            changePage(s) {
                this.getData(s);
            },
            // 获取列表
            async getData(page) {
                let param = {
                    longitude: this.$y_getKey("lon"),
                    latitude: this.$y_getKey("lat"),
                    type: 0, // 动态类型
                    user_id: this.$y_getKey("userid") || 8,
                };
                if (this.navId < -1) {
                    // 0娱乐 2交友
                    param.entertainment = this.navId == -2 ? 0 : 2;
                } else {
                    param.category_id = this.navId;
                }
                param.page = page || 1;
                let {
                    list,
                    total
                } = await this.$y_list("api/dymanic/lists", [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
            // 获取轮播
            async getSwiper() {
                let {
                    data
                } = await this.$y_ajax("api/Slide/lists", {
                    tid: 8,
                });
                this.sList = data;
            },
        },
        computed: {
            ...mapGetters(["s_getUser", "s_getCity"]),
        },
        watch: {
            s_getUser() {
                this.getData(1);
            },
            s_getCity() {
                this.getData(1);
            },
        },
    };
</script>

<style lang="less" scoped>
    .s-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>